import React, { useState, useEffect } from "react"
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';
import { isMobile } from 'react-device-detect'
import { useParams } from "react-router-dom"
import PhotoAlbum, { Album, Photo } from "react-photo-album"
import Slider from 'react-slick'
import axios from 'axios'
import { database, auth } from "./Config"
import { ref, onValue, update, set} from "firebase/database"
import { v4 as uuidv4 } from 'uuid';

import BedIcon from '@mui/icons-material/Bed'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import WifiIcon from '@mui/icons-material/Wifi'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import PoolIcon from '@mui/icons-material/Pool'
import RoofingIcon from '@mui/icons-material/Roofing'
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter'
import CollectionsIcon from '@mui/icons-material/Collections'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'; 

import { desarrollosJson } from "./desarrollo"

import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Container from "react-bootstrap/Container"
import Button from 'react-bootstrap/Button'
import Carousel from 'react-bootstrap/Carousel'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'

import "./Desarrollos.css"
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { ArrowRight } from "react-bootstrap-icons"
import property2 from "./img/property2.png"
import booking from "./img/booking.png"
import airbnb from "./img/airbnb.png"
import trip from "./img/trip_icon.png"
import vrbo from "./img/vrbo_icon.png"

//const API_PATH = "https://zaaracondos.com/zaaracondos_mailer.php";
const API_PATH = "https://digital-eureka.com/zaaracondos_mailer.php";
const SITE_KEY = "6LeiNjkpAAAAAG_JYq_fvziC__s26mktYJzXYNRA";

var settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  dots: true,
  pauseOnHover: false,
  pauseOnFocus: false,
  pauseOnDotsHover: false
};

const pathArray = window.location.pathname.split('/');

const containerStyle = {
  width: '100%',
  height: '350px'
};

const centerMark = {
  lat: 0,
  lng: 0
};

const propertiesiRef = ref(database, 'propiedades/')
const commentsRef = ref(database, 'comentarios/')

function Detalle({ reference }) {
  const [ records, setRecords ] = useState([])
  const [ properties, setProperties ] = useState([])
  const [markerPoint, setMarkerPoint] = useState('');
  const [ detalle, setDetalle ] = useState(null)
  const [ show, setShow ] = useState(false)

  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [comments, setComments] = useState("")

  const [commentName, setCommentName] = useState(null)
  const [comment, setComment] = useState(null)
  const [showComment, setShowComment] = useState(false)
  const [saving, setSaving] = useState(false)

  const [showMessage, setShowMessage] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')
  const [textBtn, setTextBtn] = useState('ENVIAR')

  useEffect(() => {
    getPropertiesList()
    getCommentsList()

    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
  
      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
  
      if (isScriptExist && callback) callback();
    }
  
    // load the script by passing the URL
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
      console.log("Script loaded!");
    });
  }, []);

  useEffect(() => {
    handleClick(reference)
  }, [reference]);
  
  const getPropertiesList = ( updated ) => {
    console.log("getting info", propertiesiRef)
    
    onValue(propertiesiRef, (snapshot) => {
      console.log("service in")
      let temp = []

      snapshot.forEach(childSnapShot => {
        let keyName = childSnapShot.key;
        let data = childSnapShot.val();
        temp.push({ "key": keyName, "data": data })
      })

      var d = temp.find((item) => item.data.id == pathArray[2]);

      if (!d) {
        setDetalle(null)
      } else {
        setDetalle(d.data)
      }

      setProperties( temp )
    }, {
      onlyOnce: true
    }
    );
  }

  const getCommentsList = () => {
    
    onValue(commentsRef, (snapshot) => {
      console.log("comments in")
      let temp = []

      snapshot.forEach(childSnapShot => {
        let keyName = childSnapShot.key;
        let data = childSnapShot.val();

        if( data.propertyid == pathArray[2] && data.active) temp.push({ "key": keyName, "data": data })
      })

      setRecords(temp)

    }
    /*, {
      onlyOnce: true
    }*/
    );
  }

  const handleOnClick = (e) => {
    e.preventDefault();
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
        handleOnSubmit(token);
      });
    });
  }

  const handleOnSubmit = (token) => {
    var form = document.getElementById('contactForm');
    var data = new FormData(form);
    data.append('recaptcha_response', token);
  
    setTextBtn("Enviando...")
    setIsActive(true)
  
    axios.post(API_PATH, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
    }).then(function(response) {
  
      if(response.data == "FAIL" || response.data == "ERROR") {
        setTextBtn("ENVIAR")
        setIsActive(false)
  
        setTitle("Error")
        setMessage("Ocurrió un error al intentar enviar tu mensaje, por favor intenta mas tarde.");
        setShowMessage(true)
      } else {
        setTextBtn("ENVIAR")
        setIsActive(false)
  
        setTitle("Mensaje enviado")
        setMessage("Tu mensaje ha sido enviado. En breve nos comunicaremos contigo. Gracias.");
        setShowMessage(true)
  
        setName("");
        setPhone("");
        setEmail("");
        setComments("");
      }
  
    }).catch(function(err) {
      setTextBtn("ENVIAR")
      setIsActive(false) 
  
      setTitle("Error")
      setMessage("Ocurrió un error al intentar enviar tu mensaje, por favor intenta mas tarde.");
      setShowMessage(true)
    });
  }

  const updateData = () => {   
    setSaving(true)
    var commentId = uuidv4()
    var selected = { data: {}, key: commentId }

    selected.data.active = false;
    selected.data.name = commentName;
    selected.data.comment = comment;
    selected.data.createdtime = new Date();
    selected.data.id = commentId;
    selected.data.propertyid = detalle.id;
    selected.data.propertytitle = detalle.title;

    console.log("to update", selected)

    const updates = {}

    updates[ selected.key ] = selected.data

    update(commentsRef, updates)
    .then( () => {
      handleCloseComment()
      setSaving( false )
      setTitle("¡Gracias!")
      setMessage("Agredecemos mucho tus comentarios. Te recordamos que tambien puedes comunicarte con nosotros mediante Whatsapp o dejandonos un mensaje mediante nuestro formulario de contacto.");
      setShowMessage(true)
      getCommentsList()
    })
    .catch((error) => {      
      setTitle("Error")
      setMessage("Ocurrió un error al intentar enviar tu mensaje, por favor intenta mas tarde.");
      setShowMessage(true)
      setSaving( false )
      console.log("error", error.message)
    })
  }

  const handleCloseComment = () => {
    setShowComment( false )
    setCommentName( null )
    setComment( null )
  }

  const handleCloseMessage = () => setShowMessage(false);

  const handleClick = (eventKey) => {
    if(eventKey)
      window.location.href = '/?r=' + eventKey
  };

  const handleMarker = () => {
    console.log("click")
    window.open("https://goo.gl/maps/AzrKRz1c2w6ShVai6")
  }

  const onLoad = map => {
    setTimeout(() => {
      setMarkerPoint(<Marker position={centerMark} onClick={ handleMarker }/>)
    }, 1000);
  }
    
  return (
    <>
      <Modal show={showMessage} onHide={handleCloseMessage}>
        <Modal.Header style={{ background: "#fff" }} closeButton>
          <Modal.Title style={{ color: "#000" }}>{ title }</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: "#000", background: "#fff" }}>{ message }</Modal.Body>
        <Modal.Footer style={{ color: "#000", background: "#fff" }}>
          <Button variant="secondary" onClick={handleCloseMessage}>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showComment} onHide={handleCloseComment}>
        <Modal.Header style={{ background: "#fff" }} closeButton>
          <Modal.Title style={{ color: "#000" }}>Agregar Comentario</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: "#000", background: "#fff" }}>
          
          <div>
            <FloatingLabel label="Nombre" className="mb-3">
              <Form.Control type="text" placeholder="Nombre" value={ commentName } onChange={ (e) => setCommentName( e.target.value ) }/>
            </FloatingLabel>
            <FloatingLabel label="Comentario" className="mb-3">
              <Form.Control as="textarea" placeholder="description" style={{ height: '100px' }} value={ comment } onChange={ (e) => setComment( e.target.value ) }/>
            </FloatingLabel>
          </div>

        </Modal.Body>
        <Modal.Footer style={{ color: "#000", background: "#fff" }}>
          <Button disabled={ saving } variant="secondary" onClick={handleCloseComment}>
            Cancelar
          </Button>
          <Button disabled={ saving } variant="primary" onClick={updateData}>
            <Spinner
              className={saving ? '' : 'hide'}
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>

      { detalle && 
        <>
        
        <div style={{ position: "relative" }}>
          <Carousel controls={ false } pause={ false } fade={ true } indicators={ false }>
            { detalle.images.slice(0, 5).map( (item, index) => (
              <Carousel.Item>
                <div className="property-carousel" style={{ backgroundImage: 'url(' + item + ')' }}></div>
              </Carousel.Item>
            ))}
          </Carousel>
          <div className="collection-btn" onClick={ () => setShow(true) }>
            <CollectionsIcon />
          </div>
        </div>

        <Container>
          <Row>
            <Col md={ 8 }>
              <Container>
                <div>
                  <br/>

                  <Container>
                    <Row>
                      <Col md={ 12 }>
                        {/*<div className="font-title-seccions" style={{ textAlign: 'left', paddingBottom: '5px', paddingLeft: '0px' }}> Detalles del Desarrollo </div>*/}
                        {/* <div className="detalle-desa">{detalle["tipodepropiedad"]}</div> */}
                        <div className="detalle-localidad"> {detalle.title}</div>
                        <div className="detalle-desa">{detalle.locality}</div>
                        {/* <div className="detalle-costo">{detalle.price}</div> */}

                        <br/>
                        <Container>
                          <Row>
                            <Col>
                              <div>
                                { detalle.desc }
                              </div>
                            </Col>
                          </Row>
                          
                          <br/>
                          <Row>
                            <div className="detalle-exteriores"> Alojamiento </div>
                          </Row>
                          <br/>
                          { ( detalle.points && detalle.points.length > 0 ) &&
                            <Row>
                              <Col md={ 6 }>
                                <ul>
                                  {detalle.points.slice(0, detalle.points.length / 2).map((exterior, index) => (
                                    <li key={index}>
                                      <div className="detalle-exterior">
                                        {exterior}
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </Col>
                              <Col md={ 6 }>
                                <ul>
                                  {detalle.points.slice((detalle.points.length / 2) + 1, detalle.points.length).map((exterior, index) => (
                                    <li key={index}>
                                      <div className="detalle-exterior">
                                        {exterior}
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </Col>
                            </Row>
                          }
                        </Container>
                      </Col>
                    </Row>
                    <br/>
                  </Container>
                </div>
              </Container>

            </Col>

            <Col md={ 4 }>
              { !isMobile &&
                <>
                  <br/>
                  <br/>
                  <br/>
                </>
              }
              
              <br/>
              <div className="contact-form-detail">
                <div className="contact-form-title"> Contáctanos para conocer mas sobre el inmueble </div>
                <br/>
                <div>
                  <Form id="contactForm" onSubmit={handleOnClick}>
                    <Container>
                      <Row>
                        <Col>
                          <FloatingLabel label="Email" className="mb-3">
                            <input type="hidden" name="property" value={ detalle.title } />
                            <Form.Control required type="email" name="email" value={email} onChange={ (e) => setEmail(e.target.value) } placeholder="" />
                          </FloatingLabel>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FloatingLabel label="Nombre" className="mb-3">
                            <Form.Control required type="text" name="name" value={name} onChange={ (e) => setName(e.target.value) } placeholder="" />
                          </FloatingLabel>
                        </Col>
                        <Col>
                          <FloatingLabel label="Teléfono" className="mb-3">
                            <Form.Control required type="number" value={phone} name="phone" onChange={ (e) => setPhone(e.target.value) } placeholder="-" />
                          </FloatingLabel>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FloatingLabel label="Mensaje" className="mb-3">
                            <Form.Control required as="textarea" name="comments" value={comments} rows="3" className="textarea-style"  onChange={ (e) => setComments(e.target.value) } placeholder="-" />
                          </FloatingLabel>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button disabled={isActive} variant="outline-primary" type="submit">
                              <Spinner
                                className={isActive ? '' : 'hide'}
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              { textBtn }
                            </Button>
                        </Col>
                      </Row>
                    </Container>
                  </Form>
                </div>
                {/* <div className="contact-form-title"> o renta mediante las siguientes plataformas </div>
                <br/>
                <div>
                  <Container>
                    <Row>
                      <Col md={ 3 } xs={ 3 }>
                        <div onClick={ () => window.open('https://www.booking.com/index.es.html', '_blank')}>
                          <img src={ airbnb } className="realstate-icon" />
                        </div>
                      </Col>
                      <Col md={ 3 } xs={ 3 }>
                        <div onClick={ () => window.open('https://www.airbnb.mx/', '_blank')}>
                          <img src={ booking } className="realstate-icon" />
                        </div>
                      </Col>
                      <Col md={ 3 } xs={ 3 }>
                        <div onClick={ () => window.open('https://www.tripadvisor.com.mx/', '_blank')}>
                          <img src={ trip } className="realstate-icon" />
                        </div>
                      </Col>
                      <Col md={ 3 } xs={ 3 }>
                        <div onClick={ () => window.open('https://www.vrbo.com/es-mx/', '_blank')}>
                          <img src={ vrbo } className="realstate-icon" />
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div> */}
              </div>
            </Col>

          </Row>
        </Container>

        <Container>
          { isMobile &&
            <>
              <br/>
            </>
          }
          <div className="detalle-exteriores">
              Ubicación
            {/*<div className="ubicacion">
              <iframe
                title="Google Maps"
                src={detalle.ubicacion}
                width="100%"
                height="400"
                style={{ border: "0" }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>*/}

            <div className='map'>
              <LoadScript googleMapsApiKey="AIzaSyAlRrxNFLSDHNRgRUNAjDocYC8No8NZWL4">
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={detalle.ubicacion}
                  zoom={ 15 }
                  onLoad = { onLoad }
                >
                  {/* markerPoint */}

                </GoogleMap>
              
              </LoadScript>
            </div>
          </div>
        </Container>  

        <Container>
          <br/>
          <br/>
          <div className="simi-title"> Comentarios </div>
          <br/>
          <div>
            <Button className='btn-general' onClick={ () => setShowComment( true ) }>
              <AddOutlinedIcon />&nbsp;&nbsp;<span>Agregar Comentario</span>
            </Button>
          </div>
          <Row>
            <Col md={ 12 }>
              { records.length == 0 && 
                <div className='testimonial-container'>Sin comentarios</div>
              }
              { records.map( item => (
                <div className='testimonial-container' style={{ paddingBottom: 0 }}>
                  <div style={{ paddingBottom: "15px" }}>
                    <span className='testimonial-title'>{ item.data.name }</span>
                  </div>
                  <div className='testimonial-desc'>
                    { item.data.comment }
                  </div>
                  <hr/>
                </div>
              ))}
            </Col>
          </Row>
        </Container>
                                  
        <Container>
          <br/>
          <br/>
          <div className="simi-title"> Propiedades que te pueden interesar </div>
          <br/>
          <Row>
            { properties.slice(0, 3).map((item, index) => (
              <Col md={ 6 } xs={ 12 } className={ isMobile ? 'col-md-4-height-mobile' : 'col-md-4-height' }>
                <div className='card-props' style={{ cursor: 'pointer' }} onClick={ () => window.location.href = '/propiedades/' + item.data.id}>
                  <div className="card-background" style={{ backgroundImage: 'url(' + item.data.preview + ')' }}>
                    <img src={ property2 } className='section-img' />
                  </div>
                  <br/>
                  <div className='card-props-desc'>
                    <Container>
                      <Row align='left'>
                        <Col md={ 12 }><b>{ item.data.title }</b></Col>
                        {/*<Col md={ 12 }>{ item.price }</Col>*/}
                      </Row>
                      <br/>
                      <Row align='left'>
                        { item.data.rooms && 
                        <Col md={ 'auto' }>
                          <span><BedIcon className='properties-items-icon'/></span> <span className='card-element'>{ item.data.rooms }</span>&nbsp;&nbsp;
                        </Col>
                        }
                        { item.data.parking && 
                        <Col md={ 'auto' }>
                          <span><DirectionsCarIcon className='properties-items-icon'/></span> <span className='card-element'> { item.data.parking }</span>&nbsp;&nbsp;
                        </Col>
                        }
                        { item.data.roof && 
                          <Col md={ 'auto' }>
                            <span><RoofingIcon className='properties-items-icon'/></span> <span className='card-element'>{ item.data.roof }</span>
                          </Col> 
                        }
                        { item.data.pool && 
                          <Col md={ 'auto' }>
                            <span><PoolIcon className='properties-items-icon'/></span> <span className='card-element'>{ item.data.pool }</span>
                          </Col> 
                        }
                        { item.data.wifi && 
                          <Col md={ 'auto' }>
                            <span><WifiIcon className='properties-items-icon'/></span> <span className='card-element'>Wifi</span>
                          </Col> 
                        }
                        { item.data.gym && 
                          <Col md={ 'auto' }>
                            <span><FitnessCenterIcon className='properties-items-icon'/></span> <span className='card-element'>Gym</span>
                          </Col> 
                        }

                        {/*<Col md={ 3 }><DirectionsCarIcon className='properties-items-icon'/> <span className="card-element">{ item.data.parking }</span></Col>
                        <Col md={ 3 }><BedIcon className='properties-items-icon'/> <span className="card-element">{ item.data.rooms }</span></Col>
                      <Col Md={ 3 }><WifiIcon className='properties-items-icon'/> <span className="card-element">Wifi</span></Col>*/}
                      </Row>
                      <div className='height-custom'/>
                      <Row align='left'>
                        <Col md={ 12 }><LocationOnIcon className='properties-items-icon'/> &nbsp;&nbsp; { item.data.locality }</Col>
                      </Row>
                    </Container>
                  </div>
                  <div style={{ height: '60px' }} />
                  <div className='card-btn-props' align='right'>
                    <Button className='btn-general' onClick={ () => window.location.href = '/propiedades/' + item.data.id}>
                      <ArrowRight />
                    </Button>
                  </div>
                </div>
              </Col>
            ))}
            {/* <Col>
              <Slider {...settings}>
                {desarrollosJson.map((item, index) => (
                  <>
                    <div className='card-props'>
                      <div>
                        <img src={ item.preview } className='section-img' />
                      </div>
                      <br/>
                      <div className='card-props-desc'>
                        <Container>
                          <Row align='left'>
                            <Col md={ 12 }><b>{ item.title }</b></Col>
                            <Col md={ 12 }>{ item.price }</Col>
                          </Row>
                          <br/>
                          <Row align='left'>
                            <Col md={ 3 }><DirectionsCarIcon className='properties-items-icon'/> &nbsp; { item.parking }</Col>
                            <Col md={ 3 }><BedIcon className='properties-items-icon'/> &nbsp; { item.rooms }</Col>
                            <Col Md={ 3 }><WifiIcon className='properties-items-icon'/> &nbsp; Wifi</Col>
                          </Row>
                          <div className='height-custom'/>
                          <Row align='left'>
                            <Col md={ 12 }><LocationOnIcon className='properties-items-icon'/> &nbsp;&nbsp; { item.locality }</Col>
                          </Row>
                        </Container>
                      </div>
                      <br/>
                      <div className='card-btn-props' align='right'>
                        <Button className='btn-general'>
                          <ArrowRight />
                        </Button>
                      </div>
                    </div>
                  </>
                ))}
              </Slider>
            </Col> */}
          </Row>
        </Container>
        
        <br/>
        <br/>


        <Modal size="m" centered show={ show } onHide={ () => setShow(false) }>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            
            <Carousel>
              { detalle.images.map( item => (
                <Carousel.Item>
                  <img src={ item } className="gallery-style" />
                </Carousel.Item>
              ))}
            </Carousel>

          </Modal.Body>
        </Modal>

        </>
      }
    </>
  );
}

export default Detalle;
