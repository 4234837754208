import { useState, useEffect, useRef } from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { FloatingWhatsApp } from 'react-floating-whatsapp'

import Home from './Home'
import Properties from './Properties'
import Detalle from './Detalle'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Modal from 'react-bootstrap/Modal'

import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'

import { Facebook, Instagram, Search } from 'react-bootstrap-icons'
import logo from './img/logo.png'
import logo_b from './img/logo_b.png'
import logoWhats from './img/zaara.png'

function App() {
  const [ reference, setReference ] = useState( null )
  const [ showPrivacy, setShowPrivacy ] = useState( false )

  return (
    <div>
      <FloatingWhatsApp 
        phoneNumber="5215523201614"
        accountName="ZaaraCondos"
        className="wa-style"
        statusMessage="En linea"
        avatar={ logoWhats }
        chatMessage="Hola, ¡Bienvenido a ZaaraCondos. ¿Cómo podemos ayudarte?"
        allowClickAway
        notification={false}
      />

      <Navbar expand="lg" fixed="top" bg="light" data-bs-theme="light" className='navbar-custom'>
        <Container>
          <div onClick={ () => window.location.href = '/'} style={{ cursor: 'pointer' }}>
            <img src={ logo } className='nav-image'/>
          </div>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Nav.Link onClick={ () => window.location.href = '/' }>
              <div className={ 'nav-selected' }>INICIO</div>
            </Nav.Link>
            <Nav.Link onClick={ () => setReference('ru') }>
              <div className={ 'nav-selected' }>NOSOTROS</div>
            </Nav.Link>
            <Nav.Link onClick={ () => window.location.href = '/propiedades' }>
              <div className={ 'nav-selected' }>PROPIEDADES</div>
            </Nav.Link>
            <Nav.Link onClick={ () => setReference('rc') }>
              <div className={ 'nav-selected' }>CONTACTO</div>
            </Nav.Link>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* <BrowserRouter>
        <Routes>
          <Route path="/" element={ <Home /> } />
          <Route path="/conduccion" element={ <Home /> } /> 
        </Routes>
      </BrowserRouter>
      */}
      
      <div style={{ height: '99px' }}></div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <Home reference={ reference } setReference={ setReference }/> } />
          <Route path="propiedades" element={ <Properties reference={ reference }/> } />
          <Route path="propiedades/:id" element={ <Detalle reference={ reference }/> } />
        </Routes>
      </BrowserRouter>

      <div></div>

      <div className='footer'>
        <Container>
          <Row className="align-items-center">
            <Col md={ 12 } align="center">
              <div><img src={ logo_b } className='nav-image'/></div>
              <br/>
              <div className="footer-text">En Zaara Condos, nuestro compromiso va más allá de encontrar un inmueble; buscamos el espacio perfecto para ti.</div>
              <br/>
              <div className='footer-text' style={ { cursor: 'pointer' } }onClick={ () => setShowPrivacy(true) }> Aviso de Privacidad </div>
              {/* <br/>
              <div>
                <Facebook className='footer-icons' />
                <Instagram className='footer-icons' />
              </div>
              */}
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        show={showPrivacy}
        onHide={() => setShowPrivacy(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton style={{ backgroundColor: "#fff" }}>
          <div style={{ fontSize: '25px', fontWeight: 'bold' }}>Aviso de Privacidad</div>
        </Modal.Header>
        <Modal.Body className="footer-privacity" style={{ backgroundColor: "#fff" }}>
          <div>INMOBILIARIO BIANCHI S.A. DE C.V.</div>
          <div>Cuauhtémoc 313 interior 16, Santa María Tepepan, Alcaldía Xochimilco, C.P.16020, Ciudad de México</div>
          <br/>
          <div>
            Es responsable de la salvaguarda uso y protección de los datos personales que nos pudiera proporcionar, y tratará los mismos de conformidad con el presente aviso de privacidad.
            <br/><br/>
            ¿Cómo puede contactarse con nuestro oficial de privacidad? Para todo lo relacionado con el tratamiento y protección de sus datos personales, usted puede contactarse con nuestro oficial de privacidad enviando un correo electrónico a la siguiente dirección: contacto@zaaracondos.com o comunicarse al teléfono 553022-5314
            <br/><br/>
            ¿Para qué fines utilizaremos sus datos personales? Su información personal será únicamente utilizada para los fines comerciales necesarios para proporcionarle nuestros productos y servicios: • Darlo de alta en nuestros sistemas como cliente o prospecto de cliente. • Enviarle cotizaciones. • Proveerle nuestros productos y servicios. • Facturarle. • Dar cumplimiento a las obligaciones contraídas con usted. • En su caso, realizar gestiones de cobranza. Adicionalmente, su información personal podría ser utilizada con las siguientes finalidades secundarias: • Informarle sobre nuestros productos o servicios, cambios en los mismos, ofertas especiales y promociones. • Realizar estudios sobre hábitos de consumo y preferencias de nuestros clientes. • Evaluar la calidad del servicio que le brindamos. Si usted no desea que sus datos personales sean tratados para las finalidades secundarias señaladas, o alguna de ellas, puede negarnos su consentimiento enviándonos su solicitud a nuestro oficial de privacidad.
            <br/><br/>
            ¿Qué datos personales recabaremos de usted? Para las finalidades antes mencionadas, podremos recabar las siguientes categorías de datos personales: • Datos de Identificación como: nombre o razón social • Datos de Contacto como: Calle y numero, colonia, c. p, número de teléfono, correo electrónico, • Datos bancarios como: número de cuenta para depósitos o transferencias de fondos.
            <br/><br/>
            ¿Con quién compartimos su información personal? No compartimos con nadie sus datos y si por una razón ajena a nosotros (judicial, de seguridad o cualquier otra) fuera necesaria pediríamos primero su consentimiento.
            <br/><br/>
            ¿Qué derechos tiene respecto a sus datos personales? En los términos permitidos por la normatividad aplicable, usted podrá acceder, rectificar o eliminar sus datos personales, así como oponerse al tratamiento de los mismos y revocar el consentimiento que para tal fin nos haya otorgado, a través de los procedimientos que hemos implementado. Para conocer dichos procedimientos, los requisitos y plazos, así como para externarnos sus comentarios, quejas o sugerencias, le agradeceremos se ponga en contacto con oficial de privacidad.
            <br/><br/>
            ¿Qué autoridad protege su información personal? Le informamos que el Instituto Federal de Acceso a la Información y Protección de Datos (IFAI), es la autoridad encargada de vigilar por la debida observancia de las disposiciones legales en materia de protección de datos personales. Para mayor información, usted puede consultar la siguiente página de Internet: www.ifai.gob.mx
            <br/><br/>
            ¿Cómo puede conocer las modificaciones y actualizaciones al presente aviso de privacidad? INMOBILIARIO BIANCHI S.A. DE C.V. podrá modificar y/o actualizar el presente aviso de privacidad, dándose a conocer a través de su página oficial de www.zaaracondos.com Fecha de la actualización más reciente: 9 de Enero de 2024
          </div>
        </Modal.Body>
      </Modal>

    </div>
  );
}

export default App;
