import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'
import { getAuth } from 'firebase/auth'

  const firebaseConfig = {
    apiKey: "AIzaSyCg_WhRQcx6-Zi66Y2b4zoht3gDnnCDl-o",
    authDomain: "zaaracondos.firebaseapp.com",
    databaseURL: "https://zaaracondos-default-rtdb.firebaseio.com/",
    projectId: "zaaracondos",
    storageBucket: "zaaracondos.appspot.com",
    messagingSenderId: "865323131440",
    appId: "1:865323131440:web:8c7e2f6cd85770d3b143f5"
  };

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const database = getDatabase(app);

export { database, auth }
