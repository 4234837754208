import { useState, useRef, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { database, auth } from "./Config"
import { ref, onValue, update, set} from "firebase/database"
import axios from 'axios'

import { desarrollosJson } from "./desarrollo"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'

import BedIcon from '@mui/icons-material/Bed'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import WifiIcon from '@mui/icons-material/Wifi'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import PoolIcon from '@mui/icons-material/Pool'
import RoofingIcon from '@mui/icons-material/Roofing'
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter'
import CollectionsIcon from '@mui/icons-material/Collections'

import airbnb from './img/airbnb_a.png'
import trip from './img/trip.png'
import booking from './img/booking_a.png'
import vrbo from './img/vrbo.png'

import line from './img/line.png'
import building from './img/building.png'
import ca from './img/ca.png'
import contract from './img/contract.png'

import home from './img/home-raf.jpg'
import property2 from './img/property2.png'
import build from './img/build.png'
import bath from './img/bath.png'
import bed from './img/bed.png'
import location_icon from './img/location.png'
import profile from './img/profile.png'

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { ArrowRight, LayoutSidebar } from 'react-bootstrap-icons';

const API_PATH = "https://digital-eureka.com/zaaracondos_mailer.php";
const SITE_KEY = "6LeiNjkpAAAAAG_JYq_fvziC__s26mktYJzXYNRA";

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const propertiesiRef = ref(database, 'propiedades/')

function Home({ reference, setReference }) {
  const [ location, setLocation ] = useState(null)
  const [ properties, setProperties ] = useState([])

  const [ show, setShow ] = useState(false)

  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [comments, setComments] = useState("")

  const [showMessage, setShowMessage] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')
  const [textBtn, setTextBtn] = useState('ENVIAR')


  const refHome = useRef()
  const ru = useRef()
  const refPartners = useRef()
  const rc = useRef()

  useEffect(() => {
    if( urlParams.get('r') ) setReference( urlParams.get('r' ) )

    getPropertiesList()

    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
  
      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
  
      if (isScriptExist && callback) callback();
    }
  
    // load the script by passing the URL
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
      console.log("Script loaded!");
    });
  }, []);

  useEffect(() => {
    handleClick(reference)
  }, [reference]);

  const getPropertiesList = ( updated ) => {
    console.log("getting info", propertiesiRef)
    
    onValue(propertiesiRef, (snapshot) => {
      console.log("service in")
      let temp = []

      snapshot.forEach(childSnapShot => {
        let keyName = childSnapShot.key;
        let data = childSnapShot.val();
        temp.push({ "key": keyName, "data": data })
      })

      console.log("data", temp)
      setProperties( temp )
    }, {
      onlyOnce: true
    }
    );
  }

  const handleClick = (eventKey) => {

    if( eventKey ) {
      var element = eval(eventKey).current;
      var headerOffset = 86;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }

  };

  const handleOnClick = (e) => {
    e.preventDefault();
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
        handleOnSubmit(token);
      });
    });
  }

  const handleCloseMessage = () => setShowMessage(false);

  const handleOnSubmit = (token) => {
    var form = document.getElementById('contactForm');
    var data = new FormData(form);
    data.append('recaptcha_response', token);
  
    setTextBtn("Enviando...")
    setIsActive(true)
  
    axios.post(API_PATH, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
    }).then(function(response) {
  
      if(response.data == "FAIL" || response.data == "ERROR") {
        setTextBtn("ENVIAR")
        setIsActive(false)
  
        setTitle("Error")
        setMessage("Ocurrió un error al intentar enviar tu mensaje, por favor intenta mas tarde.");
        setShowMessage(true)
      } else {
        setTextBtn("ENVIAR")
        setIsActive(false)
  
        setTitle("Mensaje enviado")
        setMessage("Tu mensaje ha sido enviado. En breve nos comunicaremos contigo. Gracias.");
        setShowMessage(true)
  
        setName("");
        setPhone("");
        setEmail("");
        setComments("");
      }
  
    }).catch(function(err) {
      setTextBtn("ENVIAR")
      setIsActive(false) 
  
      setTitle("Error")
      setMessage("Ocurrió un error al intentar enviar tu mensaje, por favor intenta mas tarde.");
      setShowMessage(true)
    });
  }

  return (
    <>
      <Modal show={showMessage} onHide={handleCloseMessage}>
        <Modal.Header style={{ background: "#fff" }} closeButton>
          <Modal.Title style={{ color: "#000" }}>{ title }</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: "#000", background: "#fff" }}>{ message }</Modal.Body>
        <Modal.Footer style={{ color: "#000", background: "#fff" }}>
          <Button variant="secondary" onClick={handleCloseMessage}>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>

      <div  ref={ refHome } className='home-background'>
        <div className='home-container'>

          <Container>
            <Row className='align-items-center'>
              <Col md={ isMobile ? 12 : 6 }>
                <div style={ isMobile ? { height: '30px' } : { height: '100px' } }></div>
                <div className='home-title'>
                  ¡Rentas vacacionales en CDMX y Playa del Carmen!
                </div>
                { isMobile && 
                  <>
                    <br/>
                    <img src={ home } style={{ width: '100%' }} />
                  </>
                }
                <div style={ isMobile ? { height: '0px' } : { height: '100px' } }></div>
              </Col>
              <Col>
              </Col>
            </Row>
          </Container>

          <Container className='search-bar'>
            <Row>
              <Col>
                <div>
                  <Container className='search-form'>
                    <Row className='align-items-center'>
                      {/* <Col md={ 3 }>
                        <FloatingLabel label="Ubicación">
                          <Form.Select value={ location } onChange={ (e) => setLocation( e.target.value )}>
                            <option value="1">CDMX</option>
                            <option value="2">Estado de México</option>
                            <option value="3">Puebla</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Col>
                      <Col md={ 3 }>
                        <FloatingLabel label="Tipo de propiedad">
                          <Form.Select value={ location } onChange={ (e) => setLocation( e.target.value )}>
                            <option value="1">Departamento</option>
                            <option value="2">Casa</option>
                            <option value="3">Terreno</option>
                          </Form.Select>
                        </FloatingLabel>
                      </Col>
                      <Col md={ 3 }>
                        <FloatingLabel label="Operación">
                          <Form.Select value={ location } onChange={ (e) => setLocation( e.target.value )}>
                            <option value="1">Renta</option>
                            <option value="2">Venta</option>
                          </Form.Select>
                        </FloatingLabel>
                        </Col>*/}
                      <Col md={ 4 } align='center'></Col>
                      <Col md={ 4 } align='center'>
                        <Button className='btn-general' onClick={ () => window.location.href = '/propiedades' }>
                          VER PROPIEDADES
                        </Button>
                      </Col>
                      <Col md={ 3 } align='center'></Col>
                    </Row>
                  </Container>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <div ref={ ru }>
        <div style={ isMobile ? { height: '30px' } : { height: '70px' } }></div>
        <Container className='partners-container'>
          <Row>
            <Col md={ 3 } xs={ 6 }>
              <img src={ airbnb } className='section-img-icon'/>
            </Col>
            <Col md={ 3 } xs={ 6 }>
              <img src={ booking } className='section-img-icon'/>
            </Col>
            <Col md={ 3 } xs={ 6 }>
              <img src={ trip } className='section-img-icon'/>
            </Col>
            <Col md={ 3 } xs={ 6 }>
              <img src={ vrbo } className='section-img-icon'/>
            </Col>
          </Row>
        </Container>

        <div style={ isMobile ? { height: '30px' } : { height: '70px' } }></div>
        <div className='us-background'>
          <Container>
            <Row>
              <Col className='text-center'>
                <div className='section-title' >Nosotros</div>
                <div><img src={ line } style={{ width: '180px' }} /></div>
              </Col>
            </Row>
          </Container>
          <div style={ isMobile ? { height: '30px' } : { height: '70px' } }></div>

          <Container className='partners-container'>
            <Row>
              <Col md={ 4 } xs={ 12 } className={ isMobile ? 'col-md-4-height-mobile' : 'col-md-4-height' }>
                <div className='card-style'>
                  <div>
                    <img src={ building } className='card-icon' />
                  </div>
                  <br/>
                  <div className='card-title'>Propiedades de calidad</div>
                  <br/>
                  <div className='card-desc'>Zaara Condos, donde cada día es una oportunidad para vivir una experiencia excepcional. Renta tu espacio y sumérgete en la calidad y confort que solo encontrarás aquí. Tu escape perfecto te espera.</div>
                </div>
              </Col>

              <Col md={ 4 } xs={ 12 } className={ isMobile ? 'col-md-4-height-mobile' : 'col-md-4-height' }>
                <div className='card-style'>
                  <div>
                    <img src={ ca } className='card-icon' />
                  </div>
                  <br/>
                  <div className='card-title'>Atención Personalizada</div>
                  <br/>
                  <div className='card-desc'>El servicio va más allá de tu reserva. Desde la atención puntual hasta el elogio de cientos de clientes satisfechos, cada detalle se cuida con esmero. Únete a quienes confían en nosotros y descubre la excelencia desde el primer contacto.</div>
                </div>
              </Col>

              <Col md={ 4 } xs={ 12 } className={ isMobile ? 'col-md-4-height-mobile' : 'col-md-4-height' }>
                <div className='card-style'>
                  <div>
                    <img src={ contract } className='card-icon' />
                  </div>
                  <br/>
                  <div className='card-title'>Trato directo</div>
                  <br/>
                  <div className='card-desc'>Apostamos por la conexión directa contigo. Sin intermediarios, garantizamos respuestas rápidas y soluciones eficientes. Tu experiencia es nuestra prioridad y eso comienza con una atención personalizada, directa y sin complicaciones.</div>
                </div>
              </Col>
            </Row>
          </Container>
          <div style={ isMobile ? { height: '30px' } : { height: '70px' } }></div>
        </div>
      </div>

      <div style={{ height: '100px' }}></div>
      <div ref={ refPartners } className='props-background'>
        <div className='props-container'>
        
        <div style={ isMobile ? { height: '20px' } : { height: '50px' } }></div>
          <Container>
            <Row className='align-items-center'>
              <Col></Col>
              <Col md={ 12 } lg={ 6 }>
                <div className='section-title text-right'>
                  Comprometidos a encontrar el mejor inmueble para tus planes
                </div>
                <br/>
                <div className='card-desc text-right'>
                Cada detalle cuenta en nuestra búsqueda constante por ofrecerte no solo una propiedad, sino una experiencia de vida excepcional.
                </div>
                <br/>
                <div className='text-right'>
                  <Button className='btn-general' onClick={ () => window.location.href = '/propiedades' }>
                    VER PROPIEDADES
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
          <div style={ isMobile ? { height: '20px' } : { height: '50px' } }></div>
          <Container className='props-bar'>
            <Row>
              <Col>
                <div>
                  <Container className='search-form' align='center'>
                    <Row className='align-items-center' style={{ maxWidth: '700px' }}>
                      <Col md={ 4 }>
                        <div className='section-title'>800+</div>
                        <div className='card-desc'>Reservaciones al año</div>
                      </Col>
                      <Col md={ 4 }>
                        <div className='section-title'>5</div>
                        <div className='card-desc'>Propiedades en renta</div>
                      </Col>
                      <Col md={ 4 }>
                        <div className='section-title'>5+</div>
                        <div className='card-desc'>Años de experiencia</div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <div style={ isMobile ? { height: '100px' } : { height: '150px' } }></div>
      <div>
        <Container>
          <Row>
            <Col className='text-center'>
              <div className='section-title' >Destacados</div>
              <div><img src={ line } style={{ width: '180px' }} /></div>
            </Col>
          </Row>
        </Container>
        <div style={{ height: '100px' }}></div>

        <Container>
          <Row>
            <Col lg={ 2 } md={ 0 } xs={ 0 }></Col>
            { properties.slice(0, 2).map((item, index) => (
              <Col md={ 6 } xs={ 12 } className={ isMobile ? 'col-md-4-height-mobile' : 'col-md-4-height' }>
                <div className='card-props' style={{ cursor: 'pointer' }} onClick={ () => window.location.href = '/propiedades/' + item.data.id}>
                  <div className="card-background" style={{ backgroundImage: 'url(' + item.data.preview + ')' }}>
                    <img src={ property2 } className='section-img' />
                  </div>
                  <br/>
                  <div className='card-props-desc'>
                    <Container>
                      <Row align='left'>
                        <Col md={ 12 }><b>{ item.data.title }</b></Col>
                        {/*<Col md={ 12 }>{ item.price }</Col>*/}
                      </Row>
                      <br/>
                      <Row align='left'>
                        { item.data.rooms && 
                        <Col md={ 'auto' }>
                          <span><BedIcon className='properties-items-icon'/></span> <span className='card-element'>{ item.data.rooms }</span>&nbsp;&nbsp;
                        </Col>
                        }
                        { item.data.parking && 
                        <Col md={ 'auto' }>
                          <span><DirectionsCarIcon className='properties-items-icon'/></span> <span className='card-element'> { item.data.parking }</span>&nbsp;&nbsp;
                        </Col>
                        }
                        { item.data.roof && 
                          <Col md={ 'auto' }>
                            <span><RoofingIcon className='properties-items-icon'/></span> <span className='card-element'>{ item.data.roof }</span>
                          </Col> 
                        }
                        { item.data.pool && 
                          <Col md={ 'auto' }>
                            <span><PoolIcon className='properties-items-icon'/></span> <span className='card-element'>{ item.data.pool }</span>
                          </Col> 
                        }
                        { item.data.wifi && 
                          <Col md={ 'auto' }>
                            <span><WifiIcon className='properties-items-icon'/></span> <span className='card-element'>Wifi</span>
                          </Col> 
                        }
                        { item.data.gym && 
                          <Col md={ 'auto' }>
                            <span><FitnessCenterIcon className='properties-items-icon'/></span> <span className='card-element'>Gym</span>
                          </Col> 
                        }

                        {/*<Col md={ 3 }><DirectionsCarIcon className='properties-items-icon'/> <span className="card-element">{ item.data.parking }</span></Col>
                        <Col md={ 3 }><BedIcon className='properties-items-icon'/> <span className="card-element">{ item.data.rooms }</span></Col>
                      <Col Md={ 3 }><WifiIcon className='properties-items-icon'/> <span className="card-element">Wifi</span></Col>*/}
                      </Row>
                      <div className='height-custom'/>
                      <Row align='left'>
                        <Col md={ 12 }><LocationOnIcon className='properties-items-icon'/> &nbsp;&nbsp; { item.data.locality }</Col>
                      </Row>
                    </Container>
                  </div>
                  <div style={{ height: '60px' }} />
                  <div className='card-btn-props' align='right'>
                    <Button className='btn-general' onClick={ () => window.location.href = '/propiedades/' + item.data.id}>
                      <ArrowRight />
                    </Button>
                  </div>
                </div>
              </Col>
            ))}
            <Col lg={ 2 } md={ 2 } xs={ 0 }></Col>
            {/* <Col md={ 2 }></Col>
            <Col md={ 4 }>
              <div className='card-props'>
                <div>
                  <img src={ property1 } className='section-img' />
                </div>
                <br/>
                <div className='card-props-desc'>
                  <Container>
                    <Row align='left'>
                      <Col md={ 8 }><b>Departamento en CDMX</b></Col>
                      <Col md={ 4 }>$8,000</Col>
                    </Row>
                    <br/>
                    <Row align='left'>
                      <Col md={ 3 }><img src={ bed } className='icon-small' /> &nbsp;&nbsp; 03</Col>
                      <Col md={ 3 }><img src={ bath } className='icon-small' /> &nbsp;&nbsp; 02</Col>
                      <Col md={ 6 }><img src={ build } className='icon-small' /> &nbsp;&nbsp; 16 m<sup>2</sup></Col>
                    </Row>
                    <div className='height-custom'/>
                    <Row align='left'>
                      <Col md={ 12 }><img src={ location_icon } className='icon-small' /> &nbsp;&nbsp; Ciudad de México</Col>
                    </Row>
                  </Container>
                </div>
                <br/>
                <div className='card-btn-props' align='right'>
                  <Button className='btn-general'>
                    <ArrowRight />
                  </Button>
                </div>
              </div>
            </Col>
            <Col md={ 4 }>
              <div className='card-props'>
                <div>
                  <img src={ property2 } className='section-img' />
                </div>
                <br/>
                <div className='card-props-desc'>
                  <Container>
                    <Row align='left'>
                      <Col md={ 8 }><b>Departamento en CDMX</b></Col>
                      <Col md={ 4 }>$9,000</Col>
                    </Row>
                    <br/>
                    <Row align='left'>
                      <Col md={ 3 }><img src={ bed } className='icon-small' /> &nbsp;&nbsp; 05</Col>
                      <Col md={ 3 }><img src={ bath } className='icon-small' /> &nbsp;&nbsp; 04</Col>
                      <Col md={ 6 }><img src={ build } className='icon-small' /> &nbsp;&nbsp; 300 m<sup>2</sup></Col>
                    </Row>
                    <div className='height-custom'/>
                    <Row align='left'>
                      <Col md={ 12 }><img src={ location_icon } className='icon-small' /> &nbsp;&nbsp; Estado de México</Col>
                    </Row>
                  </Container>
                </div>
                <br/>
                <div className='card-btn-props' align='right'>
                  <Button className='btn-general'>
                    <ArrowRight />
                  </Button>
                </div>
              </div>
            </Col>
            <Col md={ 2 }></Col> */}
          </Row>
        </Container>
      </div>

      <div style={ isMobile ? { height: '100px' } : { height: '150px' } }></div>
      <div>
        <Container>
          <Row>
            <Col className='text-center'>
              <div className='section-title' >Testimoniales</div>
              <div><img src={ line } style={{ width: '180px' }} /></div>
            </Col>
          </Row>
        </Container>

        <div style={ isMobile ? { height: '100px' } : { height: '100px' } }></div>

        <Container>
          <Row>
            <Col md={ 4 } className={ isMobile ? 'col-md-4-height-mobile' : 'col-md-4-height' }>
              <div className='card-props testimonial-container'>
                <div style={{ paddingBottom: "15px" }}>
                  {/*<img src={ profile } className='profile-testimonials' /> &nbsp;&nbsp; */}
                  <span className='testimonial-title'>"Experiencia Inolvidable"</span>
                </div>
                <div className='testimonial-desc'>
                  Una estancia en que superó nuestras expectativas. Desde la ubicación hasta la atención del personal. Los detalles cuidadosamente pensados en el departamento nos hicieron sentir como en casa.
                </div>
              </div>
            </Col>
            <Col md={ 4 } className={ isMobile ? 'col-md-4-height-mobile' : 'col-md-4-height' }>
              <div className='card-props testimonial-container'>
                <div style={{ paddingBottom: "15px" }}>
                  <span className='testimonial-title'>"Lujo y Comodidad en un Solo Lugar"</span>
                </div>
                <div className='testimonial-desc'>
                  La ubicación estratégica nos permitió explorar la ciudad con facilidad, mientras que el servicio impecable nos hizo sentir como VIP. Desde la reserva hasta el check-out, la atención personalizada nos hizo sentir especiales. ¡Definitivamente regresaremos!
                </div>
              </div>
            </Col>
            <Col md={ 4 } className={ isMobile ? 'col-md-4-height-mobile' : 'col-md-4-height' }>
              <div className='card-props testimonial-container'>
                <div style={{ paddingBottom: "15px" }}>
                  <span className='testimonial-title'>"Atención que Supera las Expectativas"</span>
                </div>
                <div className='testimonial-desc'>
                  Nuestra estancia fue más que un simple alojamiento; fue una gran experiencia. La atención personalizada y amigable del equipo hizo que nos sintiéramos bienvenidos desde el primer momento. La ubicación céntrica nos brindó acceso a todo lo que necesitábamos. Sin duda, recomendaríamos Zaara Condos a quienes buscan no solo un lugar para quedarse, sino una atención de calidad.
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div style={{ height: '50px' }}></div>
      </div>

      <div style={ isMobile ? { height: '50px' } : { height: '100px' } }></div>
      
      <div ref={ rc } className='contact-background'>
        <div style={{ height: '100px' }}></div>
        <Container>
          <Row>
            <Col className='text-center'>
              <div className='section-title' >¡Contáctanos! <br/>Estamos para servirte</div>
            </Col>
          </Row>
        </Container>
        <div style={ isMobile ? { height: '50px' } : { height: '50px' } }></div>

        <div align='center'>
          <div style={ isMobile ? { maxWidth: '500px' } : { maxWidth: '700px' }}>
            <Form id="contactForm" onSubmit={handleOnClick}>
              <Container>
                <Row>
                  <Col>
                    <FloatingLabel label="Email" className="mb-3">
                      <input type="hidden" name="property" value="General" />
                      <Form.Control required type="email" name="email" value={email} onChange={ (e) => setEmail(e.target.value) } placeholder="" />
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FloatingLabel label="Nombre" className="mb-3">
                      <Form.Control required type="text" name="name" value={name} onChange={ (e) => setName(e.target.value) } placeholder="" />
                    </FloatingLabel>
                  </Col>
                  <Col>
                    <FloatingLabel label="Teléfono" className="mb-3">
                      <Form.Control required type="number" value={phone} name="phone" onChange={ (e) => setPhone(e.target.value) } placeholder="-" />
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FloatingLabel label="Mensaje" className="mb-3">
                      <Form.Control required as="textarea" name="comments" value={comments} rows="3" className="textarea-style"  onChange={ (e) => setComments(e.target.value) } placeholder="-" />
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button disabled={isActive} variant="outline-primary" type="submit">
                        <Spinner
                          className={isActive ? '' : 'hide'}
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        { textBtn }
                      </Button>
                  </Col>
                </Row>
              </Container>
            </Form>
            {/*<Container className='contact-form' align='center'>
              <Row className='align-items-center'>
                <Col md={ 8 }>
                  <Form.Group>
                    <Form.Control type="text" placeholder="Ingresa tu correo" className='form-control-custom' />
                  </Form.Group>
                </Col>
                <Col md={ 4 } style={{ background: '#000' }}>
                  <Button className='btn-general'>
                    ENVIAR
                  </Button>
                </Col>
              </Row>
            </Container>*/}
          </div>
        </div>
        <div style={ isMobile ? { height: '30px' } : { height: '100px' } }></div>

      </div>

    </>
  );
}

export default Home;
