import { useState, useRef, useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import BedIcon from '@mui/icons-material/Bed'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import WifiIcon from '@mui/icons-material/Wifi'
import PoolIcon from '@mui/icons-material/Pool'
import RoofingIcon from '@mui/icons-material/Roofing'
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter'
import { database, auth } from "./Config"
import { ref, onValue, update, set} from "firebase/database"

import { desarrollosJson } from "./desarrollo"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import inmuebles24 from './img/inmuebles24.png'
import lamudi from './img/lamudi.png'
import metros from './img/metros.png'
import trovi from './img/trovi.png'

import line from './img/line.png'
import building from './img/building.png'
import ca from './img/ca.png'
import contract from './img/contract.png'

import property1 from './img/property1.png'
import property2 from './img/property2.png'
import build from './img/build.png'
import bath from './img/bath.png'
import bed from './img/bed.png'
import location_icon from './img/location.png'

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { ArrowRight, LayoutSidebar } from 'react-bootstrap-icons';

const propertiesiRef = ref(database, 'propiedades/')

function Properties({ reference }) {
  const [ properties, setProperties ] = useState([])

  useEffect(() => {
    getPropertiesList()
  }, []);

  useEffect(() => {
    handleClick(reference)
  }, [reference]);

  const getPropertiesList = ( updated ) => {
    console.log("getting info", propertiesiRef)
    
    onValue(propertiesiRef, (snapshot) => {
      console.log("service in")
      let temp = []

      snapshot.forEach(childSnapShot => {
        let keyName = childSnapShot.key;
        let data = childSnapShot.val();

        if( data.active )
          temp.push({ "key": keyName, "data": data })
      })

      console.log("data", temp)
      setProperties( temp )
    }, {
      onlyOnce: true
    }
    );
  }

  const handleClick = (eventKey) => {
    if(eventKey)
      window.location.href = '/?r=' + eventKey
  };

  return (
    <>
      <div style={{ height: '50px' }}></div>
      <div>
        <Container>
          <Row>
            <Col md={ 1 }>
            </Col>
            <Col md={ 10 }>
              <div className='title-section'>Propiedades</div>
              <br/>
              { properties.map( item => (
                <div className='card-props-container' style={{ cursor: 'pointer' }} onClick={ () => window.location.href = '/propiedades/' + item.data.id }>
                  <Container className='properties-card'>
                    <Row>
                      <Col md={ 6 } xs={ 12 } className={ isMobile ? 'no-padding' : 'col-md-cutom' }>
                        <div className='properties-image' style={{ backgroundImage: 'url(' + item.data.preview + ')' }}>
                          <img src={ property1 } className='properties-image' style={{ visibility: 'hidden' }}/>
                        </div>
                      </Col>
                      <Col md={ 6 } xs={ 12 } style={{ paddingTop: '15px', paddingRight: '15px', paddingBottom: "15px" }}>
                        <div className='properties-title'> { item.data.title } </div>
                        <div className='properties-subtitle'> { item.data.locality } </div>
                        <br/>
                        <div className='properties-price-label'> Renta </div>
                        {/*<div className='properties-price'> { item.price } </div>*/}
                        <hr/>
                        <Container>
                          <Row>
                            { item.data.rooms && 
                            <Col md={ 'auto' }>
                              <span><BedIcon className='properties-items-icon'/></span> <span className='properties-items'>{ item.data.rooms }</span>&nbsp;&nbsp;
                            </Col>
                            }
                            { item.data.parking && 
                            <Col md={ 'auto' }>
                              <span><DirectionsCarIcon className='properties-items-icon'/></span> <span className='properties-items'> { item.data.parking }</span>&nbsp;&nbsp;
                            </Col>
                            }
                            { item.data.roof && 
                              <Col md={ 'auto' }>
                                <span><RoofingIcon className='properties-items-icon'/></span> <span className='properties-items'>{ item.data.roof }</span>
                              </Col> 
                            }
                            { item.data.pool && 
                              <Col md={ 'auto' }>
                                <span><PoolIcon className='properties-items-icon'/></span> <span className='properties-items'>{ item.data.pool }</span>
                              </Col> 
                            }
                            { item.data.wifi && 
                              <Col md={ 'auto' }>
                                <span><WifiIcon className='properties-items-icon'/></span> <span className='properties-items'>Wifi</span>
                              </Col> 
                            }
                            { item.data.gym && 
                              <Col md={ 'auto' }>
                                <span><FitnessCenterIcon className='properties-items-icon'/></span> <span className='properties-items'>Gym</span>
                              </Col> 
                            }
                          </Row>
                        </Container>
                        <br/>
                        <div className='card-btn-props' align='right'>
                          <Button className='btn-general' onClick={ () => window.location.href = '/propiedades/' + item.data.id }>
                            <ArrowRight />
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              ))}
            </Col>
            <Col md={ 1 }>
            </Col>
          </Row>
        </Container>
      </div>

      <div style={{ height: '99px' }}></div>
    </>
  );
}

export default Properties;
